import React from 'react';

export const SelectLimit = ({ onLimitChange, setPage, currentLimit }) => {
    const handleLimitChange = (e) => {
        onLimitChange(Number(e.target.value)); // Asegúrate de convertir el valor a número
        setPage(1); // Reinicia la página a 1 cuando cambie el límite
    };

    return (
        <select 
            onChange={handleLimitChange} 
            className='form-select' 
            style={{ marginRight: '10px' }} 
            value={currentLimit} // Establece el valor seleccionado actual
        >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
        </select>
    );
};
