import React, { useState, useEffect, useContext } from 'react';
import { DataContext } from '../contex/DataContext';
import axios from 'axios';
import { storeColors } from '../json/colores';
import '../styles/items.css';
import { saveAs } from 'file-saver';
import { SpinnerModal } from '../components';

export const Items = () => {

    const { storaName } = useContext(DataContext)

    // Obtener OrganizationID del localStorage
    const organizationID = localStorage.getItem('organizationID');

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [xlsxFile, setXlsxFile] = useState(null); // Estado para el archivo CSV
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedStore, setSelectedStore] = useState([]);
    const [selectedStoreSelect, setSelectedStoreSelect] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [spinnerModal, setSpinnerModal] = useState(false);
    
    const [dataArticle, setDataArticle] = useState({ 
        NameStore: "", 
        SkuStore:"" ,
        NameZoho:"",
        SkuZoho:"",
        Tax_id: "",
        taxRate:""
    });

    console.log("datos: ", dataArticle)

    // UseEffect para actualizar selectedStoreSelect cuando cambia storaName
    useEffect(() => {
        if (Array.isArray(storaName) && storaName.length > 0) {
            setSelectedStoreSelect(storaName[0].name);
        }
    }, [storaName]);

    // UseEffect para hacer desaparecer el mensaje de error después de 5 segundos
    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => {
                setErrorMessage("");
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [errorMessage]);

    const articlesPerPage = 15; // Puede variar

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };
    
    const filteredSelectedStore = selectedStore?.filter(articulo =>
        articulo?.NameStore?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = filteredSelectedStore?.slice(indexOfFirstArticle, indexOfLastArticle) 
    
    const totalPages = Math.ceil(filteredSelectedStore?.length / articlesPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
    const prevPage = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));

    const getPageNumbers = () => {
        let startPage, endPage;

        if (totalPages <= 3) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 3;
            } else if (currentPage + 1 >= totalPages) {
                startPage = totalPages - 2;
                endPage = totalPages;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    };

    const pageNumbers = getPageNumbers();

    /*llamo al articulo para que se completen en los inputs de modo editar */
    const handleRowClick = async (articulo) => {
        setIsEditing(true); // Cambiar al modo de edición
        setSelectedArticle(articulo); // Establecer el artículo seleccionado
        setDataArticle({
            NameStore: '',
            SkuStore: '',
            NameZoho: '',
            SkuZoho: '',
            Tax_id: "",
            taxRate:""
        });
    
        try {
            const encodedSkuStore = await encodeURIComponent(articulo.SkuStore);
            const res = await axios.get(`https://api.connectei3.com/ep_ItemZohoEcart/getItemBySku?OrganizationID=${articulo.OrganizationID}&SkuStore=${encodedSkuStore}&Store=${articulo.Store}`);
            setDataArticle({
                NameStore: articulo.NameStore,
                SkuStore: res.data.SkuStore,
                NameZoho: res.data.NameZoho,
                SkuZoho: res.data.SkuZoho,
                Tax_id: res.data.Tax_id,
                taxRate: res.data.taxRate
            });
        } catch (error) {
            console.log(error);
            setDataArticle({
                NameStore: articulo.NameStore,
                SkuStore: articulo.SkuStore,
            });
        }
    };
    

    const handleZohoChange = (e) => {
        const { name, value } = e.target;
        setDataArticle({
            ...dataArticle,
            [name]: value
        });
    };

    /*Boton de exportar */
    const handelExport = async () => {
        if (!organizationID) {
            console.error('OrganizationID no está disponible en localStorage.');
            return;
        }
    
        try {
            const response = await axios.get(
                `https://api.connectei3.com/ep_ItemManagerEcart?OrganizationID=${organizationID}&Store=${selectedStoreSelect}`, 
                { responseType: 'arraybuffer' } // Important for handling binary data
            );
    
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'exported_items.xlsx');
        } catch (error) {
            console.error('Error al exportar los artículos:', error.message);
        }
    };
    
    // Función para importar un archivo CSV a la API
    const handelImport = async () => {
        if (!organizationID || !xlsxFile) {
            console.error('Falta OrganizationID o archivo CSV.');
            return;
        }
    
        const formData = new FormData();
        formData.append('OrganizationID', organizationID);
        formData.append('Store', selectedStoreSelect);
        formData.append('xlsxFile', xlsxFile);

        setSpinnerModal(true); 
    
        try {
            
    
            await axios.put('https://api.connectei3.com/ep_ItemManagerEcart', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
    
            // Mostrar alerta cuando se complete la importación
            alert('Archivo CSV importado correctamente.');
        } catch (error) {
            console.error('Error al importar el archivo CSV:', error.message);
            alert('Error al importar el archivo CSV. Por favor, intenta nuevamente.');
        }finally {
            setSpinnerModal(false); // Finalizar la carga
        }
    };
    

    // Función para manejar el cambio en el input de archivo CSV
    const handleFileChange = (event) => {
        setXlsxFile(event.target.files[0]);
    };

    useEffect(() => {
        if (selectedStoreSelect) {
            obtengoArticulos(selectedStoreSelect);
        }
    }, [selectedStoreSelect]);

    const obtengoArticulos = async (storeName) => {
        try {
            const res = await axios.get(`https://api.connectei3.com/ep_ItemStoreEcart/getItems?OrganizationID=${organizationID}&Store=${storeName}`);
            setSelectedStore(res.data);
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    
    const handleStoreSelection = async (e) => {
        const storeName = e.target.value;
        setSelectedStoreSelect(storeName)

        setDataArticle({
            NameStore: '',
            SkuStore: '',
            NameZoho: '',
            SkuZoho: '',
            Tax_id: '',
            taxRate: ''
        });
        
        try {
            obtengoArticulos(storeName)
            
        } catch (error) {
            console.log("Error: ", error)
        }
    };

    /*Actualiza el estado para determinar el color en el select */
    useEffect(() => {
        const selectElement = document.getElementById('storeSelect');
        
        // Función para aplicar color al select
        const applyColor = (element) => {
            const selectedOption = element.options[element.selectedIndex];
            const color = selectedOption.getAttribute('data-color');
            if (color) {
                element.style.color = color;
            } 
        };

        // Función para manejar el cambio
        const handleChange = (e) => {
            applyColor(e.target);
        };

        // Asegúrate de que el select tenga opciones antes de aplicar el color
        const applyColorWhenReady = () => {
            if (selectElement.options.length > 0) {
                applyColor(selectElement);
                // console.log("Aplicado color al select:", selectElement.style.color);
            } else {
                console.log("Esperando opciones para aplicar color...");
                // Vuelve a intentar después de un pequeño retraso
                setTimeout(applyColorWhenReady, 100);
            }
        };

        // Añadir evento de cambio
        selectElement.addEventListener('change', handleChange);

        // Aplicar color al primer elemento al cargar
        applyColorWhenReady();

        // Cleanup function
        return () => {
            selectElement.removeEventListener('change', handleChange);
        };

    }, [storaName]);

    /* modo nuevo */
    const handleNuevoClick = () => {
        setIsEditing(false);
        setDataArticle({
            NameStore: '',
            SkuStore: '',
            NameZoho: '',
            SkuZoho: '',
            Tax_id: '',
            taxRate: ''
        });
    };

    /* modo editar */
    const handleEditarClick = () => {
        setIsEditing(true);
    };

    /* creo un nuevo producto en el articulo */
    const handleNuevo = async (e) => {
        e.preventDefault();
        setSelectedArticle(null);

        const newArticle = {
            NameStore: dataArticle.NameStore,
            SkuStore: dataArticle.SkuStore,
            OrganizationID: organizationID,
            Store: selectedStoreSelect,
            NameZoho: dataArticle.NameZoho,
            SkuZoho: dataArticle.SkuZoho,
            Tax_id: dataArticle.Tax_id,
            taxRate: dataArticle.taxRate

        };

        try {
            const response = await axios.post('https://api.connectei3.com/ep_ItemManagerEcart/createItem', newArticle);

            // Actualizar el estado del artículo en selectedStore
            setSelectedStore((prevSelectedStore) => [
                ...prevSelectedStore,
                newArticle
            ]);
            
            // Limpiar el formulario 
            setDataArticle({
                NameStore: '',
                SkuStore: '',
                NameZoho: '',
                SkuZoho: '',
                Tax_id: '',
                taxRate: ''
            });
            
            setSelectedArticle(null);


        } catch (error) {
            setErrorMessage(error.response.data);

            
        }
    }

    /*edita el articulo existente  */
    const handleEditar = async (e) => {
        e.preventDefault();

        const newArticle = {
            NameStore: dataArticle.NameStore,
            SkuStore: dataArticle.SkuStore,
            OrganizationID: organizationID,
            Store: selectedStoreSelect,
            NameZoho: dataArticle.NameZoho,
            SkuZoho: dataArticle.SkuZoho,
            Tax_id: dataArticle.Tax_id,
            taxRate: dataArticle.taxRate
            
        };

        try {
            const response = await axios.put('https://api.connectei3.com/ep_ItemManagerEcart/updateItem', newArticle);
            
            // Actualizar el estado del artículo en selectedStore
            setSelectedStore((prevSelectedStore) => 
                prevSelectedStore.map((article) =>
                    article.SkuStore === selectedArticle.SkuStore ? newArticle : article
                )
            );
            
            // Limpiar el formulario 
            setDataArticle({
                NameStore: '',
                SkuStore: '',
                NameZoho: '',
                SkuZoho: '',
                Tax_id: '',
                taxRate: ''
            });
            setSelectedArticle(null);

        } catch (error) {
            console.log(error)
            setErrorMessage(error.response.data);

            
        }
    };


    return (
        <div className='articles_container'>
            {spinnerModal && <SpinnerModal />}
            <div className='articles_container_h2'>
                <h2>Artículos en</h2>
                <select name="select" onChange={handleStoreSelection} id="storeSelect">
                    {
                        storaName ? (
                            storaName.map((store, index) => (
                                <option key={index} value={store.name} style={{ color: storeColors[store.name] }}
                                    data-color={storeColors[store.name]}>
                                    {store.name}
                                </option>))) 
                            : 
                            (
                                <option>Cargando...</option>
                                
                            )
                    }
                </select>
            </div>
            
            <div className='articles_btn'>
                <input
                    className='upload-box'
                    type='file'
                    accept='.xlsx'
                    onChange={handleFileChange}
                    style={{ marginRight: '15px' }}
                />
                <button onClick={handelImport}>Importar</button>
                <button onClick={handelExport}>Exportar</button>
            </div>

            <div className='articles_subcontainer'>
                <div className='articles_subcontainer_tabla'>
                    <div className='articles_nameStore'>
                        <div></div>
                        <input 
                            type="text" 
                            placeholder='Buscar...' 
                            value={searchTerm} 
                            onChange={handleSearchChange}
                            className="input"
                        />
                    </div>
                    <div className='articles_tabla'>
                        <table className="articles_table">
                            <thead>
                                <tr>
                                    <th scope="col">Nombre en tienda</th>
                                    <th scope="col">Sku en Tienda</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    
                                    currentArticles?.map((articulo,index) => (
                                        <tr className='articles_tabla_tr' key={index} onClick={() => handleRowClick(articulo)}>
                                            <td>{articulo.NameStore}</td>
                                            <td>{articulo.SkuStore}</td>
                                        </tr>
                                    ))
                                    
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='pagination'>
                        <button onClick={prevPage} disabled={currentPage === 1} className="page_button">Previous</button>
                        {pageNumbers.map((number) => (
                            <button
                                key={number}
                                onClick={() => paginate(number)}
                                className={`page_button ${currentPage === number ? 'active' : ''}`}
                            >
                                {number}
                            </button>
                        ))}
                        <button onClick={nextPage} disabled={currentPage === totalPages} className="page_button">Next</button>
                    </div>
                </div>
                <div  className='articles_subcontainer_button'>

                    <div className='secion_btn'>
                        <button 
                            onClick={handleNuevoClick} 
                            className={!isEditing ? 'active' : ''}
                            
                        >
                            Nuevo
                        </button>
                        <button 
                            onClick={handleEditarClick} 
                            className={isEditing ? 'active' : ''}
                        >
                            Editar
                        </button>
                    </div>
                    <div className='articles_subcontainer_input'>
                        <div className='articles_input'>
                            <div className='articles_input_info_zoho'>
                                <div className='articles_input_info_input_left'>
                                    <label className="form-label">Nombre en tienda</label>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        id="Name-Tienda" 
                                        name="NameStore"
                                        value={dataArticle.NameStore} 
                                        onChange={handleZohoChange}
                                        
                                    />
                                </div>
                                <div className='articles_input_info_input_right'>
                                    <label className="form-label">Sku en Tienda</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="Sku-Tienda" 
                                        name="SkuStore"
                                        value={dataArticle.SkuStore} 
                                        onChange={handleZohoChange}
                                        disabled={isEditing}
                                    />
                                </div>
                            </div>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}

                            <div className='articles_input_info' >
                                <div className='articles_input_info_input_left'>
                                    <label className="form-label">Nombre en Zoho</label>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        id="Name-Zoho" 
                                        name="NameZoho"   
                                        value={dataArticle.NameZoho} 
                                        onChange={handleZohoChange}  
                                    />
                                </div>
                                <div className='articles_input_info_input_right'>
                                    <label className="form-label">Sku en Zoho</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="Sku-Zoho" 
                                        name="SkuZoho"  
                                        value={dataArticle.SkuZoho} 
                                        onChange={handleZohoChange}  
                                    />
                                </div>
                            </div>

                            <div className='articles_input_info' >
                                <div className='articles_input_info_input_left'>
                                    <label className="form-label">Tax id</label>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        id="Tax_id" 
                                        name="Tax_id"   
                                        value={dataArticle.Tax_id} 
                                        onChange={handleZohoChange}  
                                    />
                                </div>
                                <div className='articles_input_info_input_right'>
                                    <label className="form-label">Tax Rate</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="taxRate" 
                                        name="taxRate"  
                                        value={dataArticle.taxRate} 
                                        onChange={handleZohoChange}  
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='articles_btn_btn'>
                            {
                                isEditing ? (
                                    <button onClick={handleEditar}>Editar</button>
                                ) : (
                                    <button onClick={handleNuevo}>Crear Nuevo</button>
                                )}
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    );
};
