import React from 'react';
import logo from '../img/Logo_ei3_sin_fondo.png';
import img from '../img/logo_footer.png';
import personaje from '../img/personaje-ei3.png'
import '../styles/footer.css';

export const Footer = () => {

    return (
        <div className='footer_container'>
            <div className='footer_subcontainer'>
                <div className='footer_logo'>
                    <h2>"Date la oportunidad,</h2>
                    <h2>nosotros damos los resultados"</h2>
                    <img src={logo} alt="Logo" />
                </div>
                <div className='footer_img'>
                    <img src={img} alt="Image" />
                </div>
                <div className='footer_personaje'>
                    <img src={personaje} alt="Personaje" />
                </div>
            </div>
            <p className='footer_rights'>© 2024 Evolución i3. Todos los derechos reservados.</p>
        </div>
    )
}
