import React, { useContext, useEffect, useState } from 'react';
import '../styles/articuloDetallada.css';
import { IoAdd } from "react-icons/io5";
import { HiLogin } from "react-icons/hi";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { SelectLimit, Pagination, ArticuloInformaciónGeneral, ArticuloDetalladaHistoria, ArticuloDetalladaListaRelacionadas } from '../components';
import { DataContextPaginacion } from '../contex/DataContexPaginacion';
import logo from '../img/Logo ei3 original.jpg'
import ArticuloDetalladaTransacciones from './ArticuloDetalladaTransacciones';

export const ArticuloDetallada = () => {
    const navigate = useNavigate();
    const { id } = useParams(); 
    const location = useLocation(); 
    const articulo = location.state?.articulo; 
    const dato = location.state?.data; 
    const [showMenu, setShowMenu] = useState(true);

    const [activeSection, setActiveSection] = useState('informacionGeneral');

    // Estado para el elemento seleccionado
    const [selectedItemId, setSelectedItemId] = useState(location.state?.articulo.id);
    
    const handleItemClick = (item) => {
        
        setSelectedItemId(item.id);
        navigate(`/active-articles/${item.id}`, { 
            state: { 
                articulo: item, 
                data: dato, 
                page, 
                limit 
            } 
        });
    };

    /* ------------------- paginacion ----------------------------------*/

    const { page, setPage, limit, setLimit, handlePageChange } = useContext (DataContextPaginacion);
    const [data, setData] = useState([]);

    const getElement = (page, limit) => {
        let totalElements = dato.length;
        let adjustedLimit = Math.min(limit, totalElements); // Asegura que el límite no exceda el total de elementos
        let start = (page - 1) * adjustedLimit;
        let end = start + adjustedLimit;

        let array = dato.slice(start, end);
        setData(array);
    };

    const getLength = () => {
        return dato.length;
    };

    let totalPage = Math.ceil(getLength() / limit);

    useEffect(() => {
        getElement(page, limit);
    }, [page, limit]);


/*--------------------------------------------------------------------- */

const handlePag = (section) => {
    setActiveSection(section);
};


    return (
        <div className='articuloDetallada'>
            <div className={`${showMenu ? 'articuloDetallada-container' : 'articuloDetallada-container_no'}`}>
                <HiLogin size={24} onClick={()=>{navigate('/active-articles')}}/>
                <button className='articuloDetallada_subConatiner_btn_cerrar'
                    onClick={() => setShowMenu(!showMenu)}>
                    <span className={`${showMenu ? 'cerrar' : 'button'}`} >{showMenu ? ' <- Menu ' : 'Menu ->'}</span>
                </button>
            </div>
            
            <div className='articuloDetallada_container'>
                <div className={`articuloDetallada_subConatiner_izq ${showMenu ? '' : 'none'}`}>
                    <div  className='articuloDetallada_subConatiner_izq-tile-info'>
                        <div className='articuloDetallada_subConatiner_izq-title'>
                            <h2>Todas las órdenes de articulo</h2>
                            <div className='articuloDetallada_subConatiner_btn'>
                                <button className='articuloDetallada_subConatiner_btn_nuevo'>
                                    <IoAdd size={24}/> Nuevo
                                </button>
                                <button className='articuloDetallada_subConatiner_btn_opcion'>
                                    <span style={{ fontSize: '20px' }}>⋮</span>
                                </button>
                                

                            </div>
                        </div>
                        

                        <div className='articuloDetallada_subConatiner_lista'>
                            {data.map((item, index) => (
                                <div 
                                    key={index} 
                                    className={`articuloDetallada_subConatiner_lista-map ${(item.id === selectedItemId) && (location.state?.articulo.id === selectedItemId) ? 'selected' : ''}`} 
                                    onClick={() => handleItemClick(item)}
                                >
                                    <div className='articuloDetallada_subConatiner_lista-map-1'>
                                        <p className='map-1-cliente'>{item.nombre}</p>
                                        <p>{item.tarifa}</p>
                                    </div>
                                    <div className='articuloDetallada_subConatiner_lista-map-2'>
                                        <p>{item.unidad_de_uso}</p>
                                        <p>.</p>
                                        <p></p>
                                        <p>.</p>
                                        <p></p>
                                    </div>
                                    <div className='articuloDetallada_subConatiner_lista-map-3'>
                                        <p>fecha de vencimiento</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='articuloDetallada_pagination-container'>
                        <div className='articuloDetallada_pagination-container-select'>
                            <SelectLimit onLimitChange={setLimit} setPage={setPage} currentLimit={limit}/>
                            <Pagination totalPage={totalPage} page={page} limit={limit} 
                                siblings={1} onPageChange={handlePageChange} menu={true}/>
                        </div>
                    </div>
                </div>
                

                <div className={`${showMenu ? 'articuloDetallada_subConatiner_der' : 'articuloDetallada_subConatiner_der-sinMenu'}`}>
                    <div className='articuloDetallada_subConatiner_der_header'>
                        <h2>{articulo.nombre}</h2>

                        <div className='articuloDetallada_subConatiner_title'>
                            <p onClick={() => handlePag('informacionGeneral')}
                                className={activeSection === 'informacionGeneral' ? 'active' : ''}>
                                Información general
                            </p>
                            <p onClick={() => handlePag('transacciones')}
                                className={activeSection === 'transacciones' ? 'active' : ''}>
                                Transacciones
                            </p>
                            <p onClick={() => handlePag('listaRelacionadas')}
                                className={activeSection === 'listaRelacionadas' ? 'active' : ''}>
                                Lista relacionadas
                            </p>
                            <p onClick={() => handlePag('historia')}
                                className={activeSection === 'historia' ? 'active' : ''}>
                                Historia
                            </p>
                            <p>Generar código de barra</p>
                        </div>
                    </div>  

                    <div className='articuloDetallada_scrollable'>
                        { 
                            activeSection === 'informacionGeneral' && (
                                <ArticuloInformaciónGeneral/>
                        )}

                        {
                            activeSection === 'transacciones' && (
                                <ArticuloDetalladaTransacciones/>
                        )}
                            
                        {
                            activeSection === 'listaRelacionadas' && (
                            <ArticuloDetalladaListaRelacionadas/>
                        )} 
                        {
                            activeSection === 'historia' && (
                            <ArticuloDetalladaHistoria/>
                        )}
                        {
                            activeSection === 'generarCodigo' && (
                            <div className='articuloDetallada_subConatiner_title-codigo'>
                                <h3>Generar código de barra</h3>
                                <p>Generar un código de barra para este artículo.</p>
                                <button>Generar código</button>
                            </div>
                        )}
                    </div>  
                </div>
            </div>
        </div>
    );
}
