import React, { useContext, useEffect, useState } from 'react';
import '../styles/compraDetallada.css';
import { IoAdd } from "react-icons/io5";
import { HiLogin } from "react-icons/hi";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import img from '../img/todas las facturas3.png';
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { SelectLimit, Pagination } from '../components';
import { DataContextPaginacion } from '../contex/DataContexPaginacion';


export const CompraDetallada = () => {
    const navigate = useNavigate();
    const { id } = useParams(); 
    const location = useLocation(); 
    const compra = location.state?.compra; 
    const dato = location.state?.data; 
    const [showMenu, setShowMenu] = useState(true);

    // Estado para el elemento seleccionado
    const [selectedItemId, setSelectedItemId] = useState(location.state?.compra.id);
    
    const handleItemClick = (item) => {
        
        setSelectedItemId(item.id);
        navigate(`/orden-compra/${item.id}`, { 
            state: { 
                compra: item, 
                data: dato, 
                page, 
                limit 
            } 
        });
    };

    /* ------------------- paginacion ----------------------------------*/

    const { page, setPage, limit, setLimit, handlePageChange } = useContext (DataContextPaginacion);
    const [data, setData] = useState([]);

    const getElement = (page, limit) => {
        let totalElements = dato.length;
        let adjustedLimit = Math.min(limit, totalElements); // Asegura que el límite no exceda el total de elementos
        let start = (page - 1) * adjustedLimit;
        let end = start + adjustedLimit;

        let array = dato.slice(start, end);
        setData(array);
    };

    const getLength = () => {
        return dato.length;
    };

    let totalPage = Math.ceil(getLength() / limit);

    useEffect(() => {
        getElement(page, limit);
    }, [page, limit]);


/*--------------------------------------------------------------------- */



    return (
        <div className='compraDetallada'>
            <div className={`${showMenu ? 'compraDetallada-container' : 'compraDetallada-container_no'}`}>
                <HiLogin size={24} onClick={()=>{navigate('/orden-compra')}}/>
                <button className='compraDetallada_subConatiner_btn_cerrar'
                    onClick={() => setShowMenu(!showMenu)}>
                    <span className={`${showMenu ? 'cerrar' : 'button'}`} >{showMenu ? ' <- Menu ' : 'Menu ->'}</span>
                </button>
            </div>
            
            <div className='compraDetallada_container'>
                <div className={`compraDetallada_subConatiner_izq ${showMenu ? '' : 'none'}`}>
                    <div  className='compraDetallada_subConatiner_izq-tile-info'>
                        <div className='compraDetallada_subConatiner_izq-title'>
                            <h2>Todas las órdenes de compra</h2>
                            <div className='compraDetallada_subConatiner_btn'>
                                <button className='compraDetallada_subConatiner_btn_nuevo'>
                                    <IoAdd size={24}/> Nuevo
                                </button>
                                <button className='compraDetallada_subConatiner_btn_opcion'>
                                    <span style={{ fontSize: '20px' }}>⋮</span>
                                </button>
                                

                            </div>
                        </div>
                        

                        <div className='compraDetallada_subConatiner_lista'>
                            {data.map((item, index) => (
                                <div 
                                    key={index} 
                                    className={`compraDetallada_subConatiner_lista-map ${(item.id === selectedItemId) && (location.state?.compra.id === selectedItemId) ? 'selected' : ''}`} 
                                    onClick={() => handleItemClick(item)}
                                >
                                    <div className='compraDetallada_subConatiner_lista-map-1'>
                                        <p className='map-1-cliente'>{item.nombre_cliente}</p>
                                        <p>{item.cantidad}</p>
                                    </div>
                                    <div className='compraDetallada_subConatiner_lista-map-2'>
                                        <p>{item.orden_de_venta}</p>
                                        <p>.</p>
                                        <p>{item.fecha}</p>
                                        <p>.</p>
                                        <p>{item.sucursal}</p>
                                    </div>
                                    <div className='compraDetallada_subConatiner_lista-map-3'>
                                        <p>{item.estado}</p>
                                    </div>
                                </div>
                            ))}

                        </div>   
                    </div>
                    <div className='compraDetallada_pagination-container'>
                        <div className='compraDetallada_pagination-container-select'>
                            <SelectLimit onLimitChange={setLimit} setPage={setPage} currentLimit={limit}/>
                            <Pagination totalPage={totalPage} page={page} limit={limit} 
                                siblings={1} onPageChange={handlePageChange} menu={true}/>
                        </div>
                    </div>
                </div>

                <div className={`${showMenu ? 'compraDetallada_subConatiner_der' : 'compraDetallada_subConatiner_der-sinMenu'}`}>
                    <div className='compraDetallada_subConatiner_der_header'>
                        <span className='span'>:&nbsp;  SucursalHeadquarters</span>
                        <h2>{compra.orden_de_venta}</h2>

                        
                            <table className='facturas_subConatiner_tabla'>
                                <thead className='facturas_conatiner_tabla_title'>
                                    <tr>
                                        <th>Editar</th>
                                        <th>Enviar correo</th>
                                        <th>Compartir</th>
                                        <th>Recordatorios</th>
                                        <th>PDF/Imprimir</th>
                                        <th>Registrar pago</th>
                                        <th>Emitir DIAN</th>
                                        <th>⋮</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>  

                        <div className='compraDetallada_scrollable'>
                            <div className='compraDetallada_subConatiner_title-registre'>
                                <h3>WHAT'S NEXT?</h3>
                                <p>Se ha enviado la factura. Registre su pago tan pronto como lo reciba.</p>
                                <span>Más información</span>
                                <button>Registra pago</button>
                            </div>

                        <div className='compraDetallada_subConatiner_title-conf'>
                            <p>Reciba pago con más rapidez configurando pasarelas de pago en línea.</p>
                            <span>Configura ahora</span>
                        </div>

                        <div className='compraDetallada_subConatiner_title-orden'>
                            <p>Órdenes de ventas asociadas</p>
                        </div>

                        <div className='compraDetallada_subConatiner_PDF'>
                            <img src={img} alt="Factura PDF"/>
                        </div>

                        <div className='compraDetallada_subConatiner_info'>
                            <h5>Más información</h5>
                        </div>

                        <div className='compraDetallada_subConatiner_info-info'>
                            <label>Vendedor</label>
                            <p>: José Ignacio Salcedo</p>
                        </div>

                        <div className='compraDetallada_subConatiner_info-info'>
                            <label>Potencial</label>
                            <p>: Detector de Fugas de Sistema de Alarma INGPRO</p>
                        </div>


                        <div className='compraDetallada_subConatiner_info-info'>
                            <label>Correos electrónicos <br /> seleccionados  <AiOutlineExclamationCircle /></label>
                            <p>Daniel.dehoyos@ellevate.mx</p>
                        </div>

                        <div className='compraDetallada_subConatiner_info'>
                            <h4>CAMPOS PERSONALIZADOS</h4>
                        </div>

                        <div className='compraDetallada_subConatiner_info-info'>
                            <label>Método de Pago</label>
                            <p>:  CO_47 - Transferencia Débito Bancaria</p>
                        </div>
                        <div className='compraDetallada_subConatiner_info-info'>
                            <label>Modo de Pago</label>
                            <p>:  Credito</p>
                        </div>

                        <div className='compraDetallada_subConatiner_info-info'>
                            <label>Folio Fiscal</label>
                            <p>:  2d4e61e7-edff-4917-940e-c2a19e6f6e84</p>
                        </div>
                        <div className='compraDetallada_subConatiner_info-info'>
                            <label>URL de PDF</label>
                            <p>:  https://facturacionzoho.com/factdigital/API/PDF?UUID=2d4e61e7-edff-4917-940e-c2a19e6f6e84</p>
                        </div>

                        <div className='compraDetallada_subConatiner_info-info'>
                            <label>URL de XML</label>
                            <p>:  https://facturacionzoho.com/factdigital/API/PDF?UUID=2d4e61e7-edff-4917-940e-c2a19e6f6e84/sdsfds</p>
                        </div>

                        <div className='compraDetallada_subConatiner_info-info'>
                            <label>Fecha de Emisión</label>
                            <p>:  2024-08-08T17:56:16.904Z</p>
                        </div>
                        <div className='compraDetallada_subConatiner_info-info'>
                            <label>Número de Factura</label>
                            <p>:  CINV - 517</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
