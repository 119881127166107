import React from 'react';
import '../styles/articuloDetalladaHistoria.css'

export function ArticuloDetalladaHistoria() {
    return (
        <div className='articuloDetalladaHistoria_subConatiner_title-historia'>
            <table className='articuloDetalladaHistoria_subConatiner_tabla'>
                <thead className='articuloDetalladaHistoria_subConatiner_tabla_title'>
                    <tr>
                        <th>FECHA</th>
                        <th>DETALLES</th>
                    </tr>
                </thead>
                
                <tbody className='articuloDetalladaHistoria_subConatiner_tabla_title-td'>
                    <tr>
                        <td>2022-02-15</td>
                        <td>Actualizado por - José Ignacio Salcedo</td>
                    </tr>
                    <tr>
                        <td>2022-02-14</td>
                        <td>Actualizado por - José Ignacio Salcedo</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    )
}


