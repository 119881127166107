import React from 'react';
// import { storesData } from '../json/store';
import '../styles/consumptions.css'

export const Consumptions = ({stora}) => {
    
    return (
        <div className='consumptions_container'>
            <div className='consumption_info'>
                <div className='p_1'>
                    <p>Llamadas de entrada: </p>
                    <span>{stora?.Allstores.inputCalls ===  undefined ? 0 : stora?.Allstores.inputCalls}</span>

                </div>
                <div className='p_2'>
                    <p>Llamadas de salida: </p>
                    <span>{stora?.Allstores.outputCalls  ===  undefined ? 0 : stora?.Allstores.outputCalls}</span>

                </div>
                <div className='p_3'>
                    <p>Total de llamadas: </p>
                    <span>{stora?.Allstores.totalCalls  ===  undefined ? 0 : stora?.Allstores.totalCalls}</span>

                </div>
            </div>
        </div>
    )
}
