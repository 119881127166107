import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../img/logo vertical original baja.png';
import monito from '../img/personaje-ei3.png';
import '../styles/notFound.css';

export const NotFound = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        const isLoggedIn = Boolean(localStorage.getItem('organizationID')); 
        if (isLoggedIn) {
        navigate('/store');
        } else {
        navigate('/');
        }
    };

    return (
        <div className='notFound_container'>
        <img src={logo} alt="Logo" title="Logo" width={900} />
        <h1>Error</h1>
        <div className='notFound_subContainer'>
            <img src={monito} width={100} alt="nachito evolución i3" title="nachito evolución i3" />
            <h2>404</h2>
        </div>
        <p>No pudimos encontrar esta página</p>
        <button onClick={handleButtonClick}>Volver al inicio</button>
        </div>
    );
};
