// src/components/ArticleTable.js
import React from 'react';
import { IoAddCircleSharp } from "react-icons/io5";
import '../styles/articleTable.css'

export const ArticleTable = ({ rows, handleInputChange, addRow, removeRow }) => {
  return (
    <div className="articleTable-invoice-table">
      <div className="articleTable-table-header">
        <h3>Tabla de artículos</h3>
        <button className="articleTable-block-actions">Acciones en bloque</button>
      </div>
      <table>
        <thead>
          <tr className='articleTable-table-title'>
            <th style={{width:'35%'}}>Detalles del Artículo</th>
            <th>Clave SAT</th>
            <th style={{width:'9%'}}>Objeto de Impuesto</th>
            <th>Cantidad</th>
            <th style={{width:'6%'}}>Tarifa</th>
            <th style={{width:'10%'}}>Descuento</th>
            <th style={{width:'9%'}}>Impuesto</th>
            <th>Importe</th>
            <th style={{width:'6%'}}></th>
          </tr>
        </thead>
        <tbody className='articleTable-table-info'>
          {rows.map(row => (
            <tr key={row.id}>
              <td>
                <input style={{width:'350px' }} type="text" value={row.article} onChange={(e) => handleInputChange(row.id, 'article', e.target.value)} placeholder="Escriba o haga clic para seleccionar un artículo." />
              </td>
              <td>
                <input style={{width:'50px' }} type="text" value={row.claveSAT} onChange={(e) => handleInputChange(row.id, 'claveSAT', e.target.value)} />
              </td>
              <td>
                <input style={{width:'50px' }} type="text" value={row.impuesto} onChange={(e) => handleInputChange(row.id, 'impuesto', e.target.value)} />
              </td>
              <td>
                <input style={{width:'50px' }} type="number" value={row.cantidad} onChange={(e) => handleInputChange(row.id, 'cantidad', e.target.value)} />
              </td>
              <td>
                <input style={{width:'50px' }} type="number" value={row.tarifa} onChange={(e) => handleInputChange(row.id, 'tarifa', e.target.value)} />
              </td>
              <td>
                <input style={{width:'50px' }} type="number" value={row.descuento} onChange={(e) => handleInputChange(row.id, 'descuento', e.target.value)} />
              </td>
              <td>
                <input style={{width:'50px' }} type="text" value={row.impuesto} onChange={(e) => handleInputChange(row.id, 'impuesto', e.target.value)} />
              </td>
              <td>{row.total}</td>
              <td style={{display:'flex'}}>
                <button onClick={() => removeRow(row.id)}>⋮</button> 
                <button style={{color: 'red'}} onClick={() => removeRow(row.id)}>X</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="articleTable-table-footer">
        <button type="button" onClick={addRow}> <IoAddCircleSharp size={24} /> Añadir nueva fila</button>
        <button type="button"> <IoAddCircleSharp size={24} /> Agregar artículos a granel</button>
      </div>
    </div>
  );
};


