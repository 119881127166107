import React, { useState } from 'react';
import { CiCircleQuestion,CiSearch} from "react-icons/ci";
import '../styles/nuevaFactura.css';
import { IoAddCircleSharp } from "react-icons/io5";
import { GoArrowRight } from "react-icons/go";
import { FaArrowsRotate } from "react-icons/fa6";
import { PiGearSix } from "react-icons/pi";
import visa from "../img/pago/visa.png" ;
import mastercard from "../img/pago/mastercard.png" ;
import { ArticleTable } from '../components/ArticleTable';


export const NuevaFacrura = () => {

    const [rows, setRows] = useState([
        { id: 1, article: '', claveSAT: '', impuesto: '', cantidad: 1, tarifa: 0, descuento: 0, total: 0 },
    ]);

    const addRow = () => {
        const newRow = { id: rows.length + 1, article: '', claveSAT: '', impuesto: '', cantidad: 1, tarifa: 0, descuento: 0, total: 0 };
        setRows([...rows, newRow]);
    };

    const removeRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const handleInputChange = (id, field, value) => {
        const updatedRows = rows.map(row => {
            if (row.id === id) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setRows(updatedRows);
    };


    return (
        <form className="nueva-factura-form">
            <h2>Nueva factura</h2>

            <div className="form-group">
                <label style={{color:'#ea5253'}}>Nombre del cliente*</label>
                <div className='form-group_btn'>
                    <select style={{width: '500px'}}>
                        <option value="">Seleccionar o añadir un cliente</option>
                        {/*Mas opciones */}
                    </select>
                    <button type="button" className="btn btn-search" >
                        <CiSearch size={24}/>
                    </button>
                </div>
            </div>

            <div className="form-group">
                <label>Sucursal</label>
                <div >
                    <select className='width'>
                        <option value="headquarters">Headquarters</option>
                    </select><br />
                    <span>Source Of Supply: Nuevo León</span>
                </div>
            </div>

            <hr />

            <div className="form-group">
                <label style={{color:'#ea5253'}}>N.º de factura*</label>
                <input className='width' type="text" value="A-002172"  />
            </div>

            <div className="form-group">
                <label>Número de orden</label>
                <input className='width' type="text" />
            </div>

            <div className='nueva-factura_subcontainer'>
                <div className="nueva-factura_form-left">
                    <div className="nueva-factura_form-left-grid">
                        <label>Número de orden</label>
                        <input className='width'  type="text" />
                    </div>

                    <div className='nueva-factura_form-left-grid'>
                        <label style={{color:'#ea5253', }}>Fecha de la factura*</label>
                        <input  className='width' type="date" value="2024-07-29" />
                    </div>
                    
                </div>

                <div className="nueva-factura_form-right">
                    <div className='nueva-factura_form-right-grid'>
                        <label>Términos</label>
                        <select >
                            <option value="pagadero">Pagadero a la recepción</option>
                        </select>
                    </div>
                    <div className='nueva-factura_form-right-grid'>
                        <label>Fecha de vencimiento</label>
                        <input  type="date" value="2024-07-29" />
                    </div>
                </div>


            </div>

            <hr />

            <div className="form-group">
                <label>Vendedor</label>
                <select className='width'>
                    <option value="edgar-ayala">Edgar Ayala</option>
                </select>
            </div>

            <div className='nueva-factura_subcontainer'>
                <div className="nueva-factura_form-left">
                    <div className='nueva-factura_form-left-grid'>
                    <label>RFC</label>
                        <div>
                            <input className='width' type="TEXT" value="" />
                        </div>
                    </div>

                    <div className='nueva-factura_form-left-grid'>
                        <label style={{color:'#ea5253'}}>Forma de pago *</label>
                        <select className='width' name="manufacturer" value='' >
                            <option value="">Seleccionar o agregar fabricante</option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>Error de Timbrado</label>
                        <textarea style={{width: '300px', height: '80px'}} type="text"  value=''  placeholder='Solo puede escribir un máximo de 36000 caracteres' />
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>Falio Fiscal <CiCircleQuestion /></label>
                        <input className='width' type="text" name="ean" value=''  />
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>URL de Descarga del XML</label>
                        <input className='width' type="text" name="satKey" value=''  />
                    </div>
                        
                    <div className='nueva-factura_form-left-grid'>
                        <label style={{paddingRight: "5px"}}>No de Serie del Certificado del CSD <CiCircleQuestion /></label>
                        <input className='width' type="text" name="costUSD" value=''  />
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>Fecha y hora de certificado<CiCircleQuestion /></label>
                        <input type="text" className='width' name="saleUSD" value=''  />
                    </div>
                    <div className='nueva-factura_form-left-grid' style={{marginTop: "48px"}}>
                        <label>Sello del SAT <CiCircleQuestion /></label>
                        <textarea style={{width: '300px', height: '80px'}} type="text"  value=''  placeholder='Solo puede escribir un máximo de 36000 caracteres' />
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label style={{color:'#ea5253'}}>Exportacion * </label>
                        <select className='width' value='' >
                            <option value=""></option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>XML</label>
                        <textarea style={{width: '300px', height: '80px'}} type="text"  value=''  placeholder='Solo puede escribir un máximo de 36000 caracteres' />
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>PAIMENT ID</label>
                        <input className='width' type="text" value=''  />
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>Medios de pago <CiCircleQuestion /></label>
                        <select className='width' name="manufacturer" value='' >
                            <option value="">CO_ZZZ - Acuerdo mutuo</option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>Folio Fiscal</label>
                        <input className='width' type="text" value=''  />
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>URL de XML</label>
                        <input className='width' type="text" value=''  />
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>Ultimo Evento</label>
                        <input className='width' type="text" value=''  />
                    </div>
                    <div className='nueva-factura_form-left-grid'>
                        <label>Número de Factura</label>
                        <input className='width' type="text" value=''  />
                    </div>
                    </div>

                    <div className="nueva-factura_form-right">

                        <div className='nueva-factura_form-right-grid'>
                            <label style={{color:'#ea5253'}}>USO CFDI *</label>
                            <select className='width' name="manufacturer" value='' >
                                <option value="">G03 Gastos en General</option>
                                {/* Añade opciones aquí */}
                            </select>
                        </div>
                        <div className='nueva-factura_form-right-grid'>
                            <label style={{color:'#ea5253'}}>Método de Pago *</label>
                            <select className='width'  value='' >
                                <option value="">PPD PAGO EN PARCIALIDADES</option>
                                {/* Añade opciones aquí */}
                            </select>
                        </div>
                        <div className='nueva-factura_form-right-grid input-check'>
                            <label>No Timbrar</label>
                            <div>
                                <input type="checkbox"  checked=''/>
                            </div>
                        </div>
                        <div className='nueva-factura_form-right-grid' style={{marginTop: "73px"}}>
                            <label>URL de Descarga PEF</label>
                            <input type="text"  value='' />
                        </div>
                        <div className='nueva-factura_form-right-grid'>
                            <label>Estado de la SAT</label>
                            <select value='' >
                                <option value=''></option>
                                {/* Add more options here */}
                            </select>
                        </div>
                        
                        <div className='nueva-factura_form-right-grid'>
                            <label style={{paddingRight: "7px"}}>No de Serie del Certificado del SAT: <CiCircleQuestion /></label>
                            <input type="text"  value='' />
                        </div>
                        <div className='nueva-factura_form-right-grid'>
                            <label>Sello Digital del CFDI <CiCircleQuestion /></label>
                            <textarea style={{ height: '80px'}} type="text"  value=''  placeholder='Solo puede escribir un máximo de 36000 caracteres' />
                        </div>
                        <div className='nueva-factura_form-right-grid'>
                            <label style={{paddingRight: "7px", marginTop: "10px"}}>Cadena original del complemento de certificación digital del SAT <CiCircleQuestion /></label>
                            <textarea style={{ height: '80px'}} type="text"  value=''  placeholder='Solo puede escribir un máximo de 36000 caracteres' />
                        </div>
                        <div className='nueva-factura_form-right-grid' style={{ marginTop: "10px"}}>
                            <label>Motivo de Cancelacion</label>
                            <select  value='' >
                                <option value="">00 - No Aplica</option>
                                {/* Añade opciones aquí */}
                            </select>
                        </div>
                        <div className='nueva-factura_form-right-grid' style={{ marginBottom: '70px'}}>
                            <label>Recurente</label>
                            <div>
                                <input type="checkbox"  checked=''/>
                            </div>
                        </div>
                        <div className='nueva-factura_form-right-grid input-check' style={{marginTop: "-4px", marginBottom: '30px'}}>
                            <label>Autofacturación</label>
                            <div>
                                <input type="checkbox"  checked=''/>
                            </div>
                        </div>
                        <div className='nueva-factura_form-right-grid'>
                            <label>Modo de pago <CiCircleQuestion /></label>
                            <select  value='' >
                                <option value="">Contado</option>
                                <option value="">Tanferencia</option>
                                <option value="">Tarjeta</option>
                                {/* Añade opciones aquí */}
                            </select>
                        </div>
                        <div className='nueva-factura_form-right-grid'>
                            <label>URL de PDF</label>
                            <input type="text"  value='' />
                        </div>
                        <div className='nueva-factura_form-right-grid'>
                            <label>Primer Consecutivo</label>
                            <input type="text"  value='' />
                        </div>
                        <div className='nueva-factura_form-right-grid'>
                            <label>QR de Factura</label>
                            <input type="text"  value='' />
                        </div>
                        <div className='nueva-factura_form-right-grid'>
                            <label>Fecha de Emisión</label>
                            <input type="text"  value='' />
                        </div>

                    </div>
                </div>

                <hr />


                <div className="form-group">
                    <div>
                        <label>Almacen</label>
                        <select  value='' >
                            <option value="">Evolucion i3</option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                    <div >
                        <select style={{padding: '8px 50px'}} value='' >
                            <option value="">...</option>
                            {/* Añade opciones aquí */}
                        </select>
                    </div>
                </div>

                <ArticleTable
                    rows={rows}
                    handleInputChange={handleInputChange}
                    addRow={addRow}
                    removeRow={removeRow}
                />

                <div className="invoice-footer">
                    <div className="invoice-notes">
                        <label>Notas del cliente</label>
                        <textarea ></textarea>
                        <span>Aparecerán en la factura</span>
                    </div>
                    <div className="invoice-totals">
                        <div  className="invoice-totals-subtotal">
                            <label style={{fontWeight:'700'}} htmlFor="">Subtotal</label>
                            <input className='total-input' type="text"  value='' placeholder='0.00'/>
                        </div>
                        <div className="invoice-totals-cargos">
                            <div className="invoice-totals-cargos-label">
                                <label htmlFor="">Cargos de envío</label>
                                <p>Aplica impuesto sobre <br /> los gastos de envio</p>
                            </div>
                            <div className="invoice-totals-cargos-input">
                                <input className="invoice-totals-input" type="text"  value='' />
                                <CiCircleQuestion size={24}/>
                            </div>
                            <div className="invoice-totals-cargos-in">
                                <input className='total-input' type="text"  value='' placeholder='0.00'/>
                            </div>
                        </div>
                        <div className="invoice-totals-cargos">
                            <div  className="invoice-totals-cargos-label">
                                <label htmlFor="">Ajuste</label>
                            </div>
                            <div className="invoice-totals-cargos-input">
                                <input className="invoice-totals-input" type="text"  value='' />
                                <CiCircleQuestion size={24} />
                            </div>
                            <div className="invoice-totals-cargos-in">
                                <input className='total-input' type="text"  value='' placeholder='0.00'/>
                            </div>
                        </div>

                        <div className="invoice-totals-subtotal">
                            <label style={{fontWeight:'700'}}  htmlFor="">Total (MXN$)</label>
                            <input className='total-input' type="text"  value='' placeholder='0.00'/>
                            
                        </div>
                    
                    </div>

                </div>

                <hr />

                <div className='form-group'>
                    <div>
                        <p>Términos y condiciones</p>
                        <textarea
                            style={{ width: '600px', height: '100px' }}
                            type="text"
                            value=''
                            placeholder='PRODUCTOS Y SERVICIOS VENDIDOS ESTÁN EXPRESAMENTE SUJETOS A LOS TÉRMINOS Y CONDICIONES ESTABLECIDOS EN EL PRESENTE Y EN EL SITIO WEB DE EVOLUCIÓN I3, WWW.EVOlUCIONI3.COM, SIN PERJUICIO DE CUALQUIER TÉRMINO CONTRADICTORIO DE LA ORDEN'
                        />
                    </div>
                    <div className='form-group_archivo'>
                        <p>Adjuntar archivo(s) a Factura</p>
                        <input type="file" multiple /> <br />
                        <span >Puede cargar un máximo de 10 archivos, cada uno de 10 MB</span>
                    </div>
                </div>

                <div className='form-group-formadepago'>
                    <p>Seleccione una opción de pago en linea para que le paguen más rápido 
                        <img style={{ height:'40px'}} src={mastercard} alt="" />
                        <img style={{ height:'50px'}} src={visa} alt="" />
                        <span style={{color: '#007bff', marginLeft:'10px'}}> <PiGearSix /> Pasarela de pago</span>
                    </p>
                    
                    <div className="payment-options">

                        <label className="payment-options_input">
                            <input type="checkbox" name="paymentOption" value="paypal" />
                            PayPal
                        </label>
                        <label>
                            <input type="checkbox" name="paymentOption" value="stripe" />
                            Stripe
                        </label>
                        <label>
                            <input type="checkbox" name="paymentOption" value="mercadoPago" />
                            Mercado Pago
                        </label>
                    </div>
                    <p style={{marginTop:'50px'}}>Campos adicionales:Para agregar campos personlizados para sus facturas, acceda a Configuración, <GoArrowRight /> Ventas <GoArrowRight /> facturas.</p>
                </div>


            

            <hr />
            <div className='nuevaFactura_footer'>
                <div className='nuevaFactura_footer_btn'>
                    <button className='btn footer_btn'>Guardar como borrador</button>
                    <button className='btn btn-primary'>Guardar y enviar</button>
                    <button className='btn footer_btn'>Cancelar</button>
                </div>

                <div className='nuevaFactura_footer_total'>
                    <p className='nuevaFactura_footer_total_p'> <FaArrowsRotate />Hacer recurrente</p>
                    <div className='nuevaFactura_footer_cantidadTotal'>
                        <p>Cantidad total: MXN$ 0.00</p>
                        <span>Cantidad total: 0</span>
                    </div>
                </div>

            </div>

        </form>
    );       
}
