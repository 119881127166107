import React, { useState, useEffect, useRef } from 'react';
import '../styles/articuloDetalladaListaRelacionadas.css';
import { GoChevronDown, GoChevronRight } from "react-icons/go";

export const ArticuloDetalladaListaRelacionadas = () => {
    const [visibleSections, setVisibleSections] = useState({
        articulos: true, 
        item: false,
        costo: false,
    });

    const articulosRef = useRef(null);
    const itemRef = useRef(null);
    const costoRef = useRef(null);

    const handleSelect = (section) => {
        setVisibleSections(prevState => ({
            ...prevState,
            [section]: true, 
        }));

        if (section === 'articulos' && articulosRef.current) {
            articulosRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (section === 'item' && itemRef.current) {
            itemRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (section === 'costo' && costoRef.current) {
            costoRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const toggleSection = (section) => {
        setVisibleSections(prevState => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    useEffect(() => {
        handleSelect('articulos');
    }, []);

    return (
        <div className='articuloDetalladaListaRelacionadas_subConatiner_title-transacciones'>
            <div className='articuloDetalladaListaRelacionadas_subConatiner_filter'>
                <div className='articuloDetalladaListaRelacionadas_subConatiner_filter-info'>
                    <label>Ir a:</label>
                    <select onChange={(e) => handleSelect(e.target.value)}>
                        <option value="articulos">Artículos</option>
                        <option value="item">Item</option>
                        <option value="costo">Costo</option>
                    </select>
                </div>
            </div>
            <div className='articuloDetalladaListaRelacionadas_subConatiner_contenido'>
                <div className='articuloDetalladaListaRelacionadas_subConatiner_contenido-info' ref={articulosRef}>
                    <button onClick={() => toggleSection('articulos')}>
                        <label className={visibleSections.articulos ? "true-labal" : ""}>
                            {visibleSections.articulos ? <GoChevronDown size={24} /> : <GoChevronRight size={24}/>} Artículos
                        </label>
                        {visibleSections.articulos && (
                            <div className='articuloDetalladaListaRelacionadas_subConatiner_title-historia'>
                                <table className='articuloDetalladaListaRelacionadas_subConatiner_tabla'>
                                    <thead className='articuloDetalladaListaRelacionadas_subConatiner_tabla_title'>
                                        <tr>
                                            <th>INVENTARIO ARRENDADO NAME</th>
                                            <th>CREADO POR</th>
                                            <th>CLIENTE</th>
                                            <th>ARTÍCULO</th>
                                            <th>N/S</th>
                                            <th>SKU RENTA</th>
                                        </tr>
                                    </thead>
                                    <tbody className='articuloDetalladaListaRelacionadas_subConatiner_tabla_title-td'>
                                        <tr>
                                            <td colSpan="6"> No hay transacciones disponibles</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </button>
                </div>

                <div className='articuloDetalladaListaRelacionadas_subConatiner_contenido-info' ref={itemRef}>
                    <button onClick={() => toggleSection('item')}>
                        <label className={visibleSections.item ? "true-labal" : ""}>
                            {visibleSections.item ? <GoChevronDown size={24} /> : <GoChevronRight size={24}/>}  Item
                        </label>
                        {visibleSections.item && (
                            <div className='articuloDetalladaListaRelacionadas_subConatiner_title-historia'>
                                <table className='articuloDetalladaListaRelacionadas_subConatiner_tabla'>
                                    <thead className='articuloDetalladaListaRelacionadas_subConatiner_tabla_title'>
                                        <tr>
                                            <th>CREADO POR</th>
                                            <th>NDP</th>
                                            <th>ITEM</th>
                                            <th>NÚMERO DE SERIE</th>
                                            <th>ID DE FACTURA</th>
                                            <th>NÚMERO DE FACTURA</th>
                                            <th>ESTADO</th>
                                            <th>NÚMERO DE PEDIMENTO</th>
                                        </tr>
                                    </thead>
                                    <tbody className='articuloDetalladaListaRelacionadas_subConatiner_tabla_title-td'>
                                        <tr>
                                            <td colSpan="8"> No hay transacciones disponibles</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </button>
                </div>

                <div className='articuloDetalladaListaRelacionadas_subConatiner_contenido-info' ref={costoRef}>
                    <button onClick={() => toggleSection('costo')}>
                        <label className={visibleSections.costo ? "true-labal" : ""}>
                            {visibleSections.costo? <GoChevronDown size={24} /> : <GoChevronRight size={24}  />}Costo
                        </label>
                        {visibleSections.costo && (
                            <div className='articuloDetalladaListaRelacionadas_subConatiner_title-historia'>
                                <table className='articuloDetalladaListaRelacionadas_subConatiner_tabla'>
                                    <thead className='articuloDetalladaListaRelacionadas_subConatiner_tabla_title'>
                                        <tr>
                                            <th>FECHA</th>
                                            <th>SUSCURSAL</th>
                                            <th>NÚMERO DE ESTIMACIÓN</th>
                                            <th>NÚMERO DE REFERENCIA</th>
                                            <th>NOMBRE DEL CLIENTE</th>
                                            <th>ESTADO</th>
                                            <th>CANTIDAD</th>
                                        </tr>
                                    </thead>
                                    <tbody className='articuloDetalladaListaRelacionadas_subConatiner_tabla_title-td'>
                                        <tr>
                                            <td colSpan="7"> No hay transacciones disponibles</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}
