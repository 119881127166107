import React, { useState, useEffect } from 'react';
import '../styles/store.css';
import { Calendar, Consumptions, Graph, SpinnerModal } from '../components';
import { storeColors } from '../json/colores';
import axios from 'axios';
import dayjs from 'dayjs';

export const Store = () => {
    const [modal, setModal] = useState(false);
    const [organizationID, setOrganizationID] = useState(null);
    const [consumptionDate, setConsumptionDate] = useState(null);
    const [stora, setStora] = useState(null);
    const [spinnerModal, setSpinnerModal] = useState(true);



    // Establece el primer día del mes actual como fecha inicial
    useEffect(() => {
        const today = dayjs();
        const firstDayOfCurrentMonth = today.startOf('month').format('YYYY-MM-01');
        setConsumptionDate(firstDayOfCurrentMonth);
    }, []);

    // Obtener organizationID desde localStorage
    useEffect(() => {

        const orgID = localStorage.getItem('organizationID');
        setOrganizationID(orgID);

    }, [organizationID]);


    // Hacer la solicitud para obtener los datos de consumo
    const getStores = async () => {
        if (organizationID && consumptionDate) {
            try {
                const res = await axios.post('https://api.connectei3.com/ep_StoreConsumptionEcart/retrieveAllConsumption', {
                    ConsumptionDate: consumptionDate,
                    OrganizationID: organizationID
                });
                
                if (res.status === 200) {
                    setStora(res.data);
                } else {
                    setStora(null);
                }
            } catch (error) {
                console.log('Error al realizar la solicitud:', error);
                setStora(null);
            } finally {
                setSpinnerModal(false); // Siempre se oculta el spinner después de la solicitud
            }
        } else {
            console.log('No se encontró ningún ID o fecha.');
            setStora(null);
            setSpinnerModal(true); // Mostrar el spinner si faltan datos
        }
    };

    const redirectToOauth = async () => {
        try {
            window.open("https://oauth.ecartapi.com/2kUQSJaLLbzdWw9xEL4Ccesm7rHRK9yJ?nobar=true", '_blank');
        } catch (e) {
            console.log('Error al redirigir a Oauth:', e);
        }
    };

    useEffect(() => {
        getStores();
    }, [organizationID, consumptionDate]);

    // Ordenar los datos de las tiendas
    const sortedStores = stora?.stores ? [...stora.stores].sort((a, b) => b.totalCalls - a.totalCalls) : [];

    // Obtener la ruta de la imagen
    const getImagePath = (storeName) => {
        const formattedName = storeName.toLowerCase().replace(/\s+/g, '');
        try {
            return require(`../img/logoStore/${formattedName}.png`);
        } catch (err) {
            return require(`../img/logoStore/default.png`);
        }
    };

    // Datos para el gráfico
    const graphData = sortedStores.map(store => ({
        name: store.name,
        totalCalls: store.totalCalls,
        color: storeColors[store.name] || "#8884d8"
    }));

    // Función para actualizar la información de una tienda
    const handleUpdate = async (storeName) => {
        //const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
        const endDate = dayjs().endOf('month').format('YYYY-MM-DD');

        try {
            const res = await axios.post('https://api.connectei3.com/ep_EcartOrders/getSyncOrdersFromStore', {
                OrgID: organizationID,
                StoreName: storeName,
                startDate: consumptionDate,
                endDate: endDate
            });

            if (res.status === 200) {
                await getStores();
            } else {
                console.log("Error en la actualización:", res.statusText);
            }
        } catch (error) {
            console.log("Error en la solicitud de actualización:", error);
        }
    };
    


    return (
        <div className="store_container">
            {spinnerModal && <SpinnerModal />}

            <div>
                <div className='store_subContainer_h2'>
                    <h2>Mis Tiendas</h2>
                </div>
                
                <div className="store_subContainer_titan">
                    <Calendar setConsumptionDate={setConsumptionDate} />
                    
                    <div className="store_subContainer_info">
                        <div className='store_subContainer_info_btn'>
                            <button
                                className='store_subContainer_info_grafica'
                                onClick={() => setModal(true)}
                            >
                                Gráfica
                            </button>
                            <button onClick={() => redirectToOauth()} className='store_subContainer_info_pag'>Nueva Tienda</button>
                        </div>
                    </div>
                </div>

                <div className="store_subContainer_info_total">
                    <Consumptions stora={stora} />
                </div>

                {modal && <Graph setModal={setModal} data={graphData} />}

                <div className="store_subContainer">
                    <p>Llamadas por Tienda</p>

                    <div className="table-container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="col-1">Logo</th>
                                    <th className="col-3">Tienda</th>
                                    <th className="col-3">Entrada</th>
                                    <th className="col-3">Salida</th>
                                    <th className="col-3">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedStores.map(store => (
                                    <tr key={store._id}>
                                        <td data-label="Logo" style={{ backgroundColor: storeColors[store.name] }}>
                                            <img src={getImagePath(store.name)} alt={store.name} className="store_image" />
                                        </td>
                                        <td data-label="Tienda">{store.name}</td>
                                        <td data-label="Entrada">{store.inputCalls}</td>
                                        <td data-label="Salida">{store.outputCalls}</td>
                                        <td data-label="Total">{store.totalCalls}</td>
                                        <td>
                                            {store.name === 'Walmart' && (
                                                <button onClick={() => handleUpdate(store.name)}
                                                    className='store_btn_actualizar'>
                                                    Actualizar
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>

            </div>
        </div>
    );
};
