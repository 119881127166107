export const activeArticles = {
    "articles": [
        {
            "id": 1,
            "nombre": "API MarketPlaces",
            "sku": "MKPLS-001",
            "descripcion_compra": "",
            "tarifa_compra": "0",
            "descripcion": "Llamadas de API para Mark...",
            "tarifa": "MXN$0.19",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "E48 - Servicio"
        },
        {
            "id": 2,
            "nombre": "Facturación Panamá Anual",
            "sku": "FPA-001",
            "descripcion_compra": "",
            "tarifa_compra": "MXN$0.00",
            "descripcion": "Alcance del Contrato de Se...",
            "tarifa": "MXN$2,153.75",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "E48 - Servicio"
        },
        {
            "id": 3,
            "nombre": "test 2",
            "sku": "",
            "descripcion_compra": "",
            "tarifa_compra": "0",
            "descripcion": "",
            "tarifa": "MXN$1,199.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "E48 - Servicio"
        },
        {
            "id": 4,
            "nombre": "gorra xy",
            "sku": "",
            "descripcion_compra": "",
            "tarifa_compra": "0",
            "descripcion": "",
            "tarifa": "MXN$1,500.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "E48 - Servicio"
        },
        {
            "id": 5,
            "nombre": "test lotes",
            "sku": "TL",
            "descripcion_compra": "",
            "tarifa_compra": "MXN$2.00",
            "descripcion": "",
            "tarifa": "MXN$2.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "H87 - Pieza"
        },
        {
            "id": 6,
            "nombre": "Borrar cubeta 20 lts",
            "sku": "b c 20ls",
            "descripcion_compra": "",
            "tarifa_compra": "MXN$84.00",
            "descripcion": "",
            "tarifa": "MXN$275.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "H87 - Pieza"
        },
        {
            "id": 7,
            "nombre": "borrar cubeta 20 lts",
            "sku": "VBorrar 20l",
            "descripcion_compra": "",
            "tarifa_compra": "MXN$35.00",
            "descripcion": "",
            "tarifa": "MXN$70.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "H87 - Pieza"
        },
        {
            "id": 8,
            "nombre": "Borrar 1000 Lts",
            "sku": "borrar 1000",
            "descripcion_compra": "",
            "tarifa_compra": "MXN$393.00",
            "descripcion": "Características principales ...",
            "tarifa": "MXN$600.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "H87 - Pieza"
        },
        {
            "id": 9,
            "nombre": "Diadema monoaura...",
            "sku": "HT201",
            "descripcion_compra": "",
            "tarifa_compra": "",
            "descripcion": "Caracteristicas principales",
            "tarifa": "MXN$600.00",
            "existencias_a_mano": "",
            "unidad_de_uso": ""
        },
        {
            "id": 10,
            "nombre": "API MarketPlaces",
            "sku": "MKPLS-001",
            "descripcion_compra": "",
            "tarifa_compra": "0",
            "descripcion": "Llamadas de API para Mark...",
            "tarifa": "MXN$0.19",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "E48 - Servicio"
        },
        {
            "id": 11,
            "nombre": "Facturación Panamá Anual",
            "sku": "FPA-001",
            "descripcion_compra": "",
            "tarifa_compra": "MXN$0.00",
            "descripcion": "Alcance del Contrato de Se...",
            "tarifa": "MXN$2,153.75",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "E48 - Servicio"
        },
        {
            "id": 12,
            "nombre": "test 2",
            "sku": "",
            "descripcion_compra": "",
            "tarifa_compra": "0",
            "descripcion": "",
            "tarifa": "MXN$1,199.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "E48 - Servicio"
        },
        {
            "id": 13,
            "nombre": "gorra xy",
            "sku": "",
            "descripcion_compra": "",
            "tarifa_compra": "0",
            "descripcion": "",
            "tarifa": "MXN$1,500.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "E48 - Servicio"
        },
        {
            "id": 14,
            "nombre": "test lotes",
            "sku": "TL",
            "descripcion_compra": "",
            "tarifa_compra": "MXN$2.00",
            "descripcion": "",
            "tarifa": "MXN$2.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "H87 - Pieza"
        },
        {
            "id": 15,
            "nombre": "Borrar cubeta 20 lts",
            "sku": "b c 20ls",
            "descripcion_compra": "",
            "tarifa_compra": "MXN$84.00",
            "descripcion": "",
            "tarifa": "MXN$275.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "H87 - Pieza"
        },
        {
            "id": 16,
            "nombre": "borrar cubeta 20 lts",
            "sku": "VBorrar 20l",
            "descripcion_compra": "",
            "tarifa_compra": "MXN$35.00",
            "descripcion": "",
            "tarifa": "MXN$70.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "H87 - Pieza"
        },
        {
            "id": 17,
            "nombre": "Borrar 1000 Lts",
            "sku": "borrar 1000",
            "descripcion_compra": "",
            "tarifa_compra": "MXN$393.00",
            "descripcion": "Características principales ...",
            "tarifa": "MXN$600.00",
            "existencias_a_mano": "0.00",
            "unidad_de_uso": "H87 - Pieza"
        },
        {
            "id": 18,
            "nombre": "Diadema monoaura...",
            "sku": "HT201",
            "descripcion_compra": "",
            "tarifa_compra": "",
            "descripcion": "Caracteristicas principales",
            "tarifa": "MXN$600.00",
            "existencias_a_mano": "",
            "unidad_de_uso": ""
        }
    ]
};
