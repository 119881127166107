import React,{useState} from 'react';
import '../styles/recoverPassword.css'

export const RecoverPassword = () => {

    const [identifier, setIdentifier] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');

        // Simulo la llamada al backend
        await new Promise((resolve) => setTimeout(resolve, 2000));

        /*
        try {
            const response = await axios.post('URL_DEL_ENDPOINT', {
                identifier,
            });

            if (response.status === 200) {
                setMessage('El correo de recuperación se ha enviado correctamente.');
            } else {
                setMessage('Hubo un error al enviar el correo de recuperación.');
            }
        } catch (error) {
            setMessage('Hubo un error al enviar el correo de recuperación.');
        }
        */

        setIsLoading(false);
        setMessage('El correo de recuperación se ha enviado correctamente.');

    };
    
    return (
        <div className="recoverPassword_container">
            <div className="recoverPassword_subContainer">
                <h2>Recuperar Contraseña</h2>
                <form className="recoverPassword_form" onSubmit={handleSubmit}>
                    <div className="mb-1 wide">
                        <label htmlFor="identifier" className="form-label">Email:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="identifier"
                            value={identifier}
                            onChange={(e) => setIdentifier(e.target.value)}
                            required
                            disabled={isLoading}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary btn-lg mt-3" disabled={isLoading}>
                        {isLoading ? <span className="password-recovery-spinner"></span> : 'Enviar'}
                    </button>
                </form>
                {message && <p className="success-message">{message}</p>}
            </div>
        </div>
    )
}


