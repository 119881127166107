import React, { useContext, useState, useEffect } from 'react';
import { activeArticles } from '../json/art¡culosActivos';
import { IoAdd } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import '../styles/activeArticles.css';
import { SelectLimit, Pagination } from '../components';
import { useNavigate } from 'react-router-dom';
import { DataContextPaginacion } from '../contex/DataContexPaginacion';

export const ActiveArticles = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { page, setPage, limit, setLimit, handlePageChange } = useContext(DataContextPaginacion);

    /* ------------------- paginacion ----------------------------------*/

    const getElement = (page, limit) => {
        let totalElements = activeArticles.articles.length;
        let adjustedLimit = Math.min(limit, totalElements); // Asegura que el límite no exceda el total de elementos
        let start = (page - 1) * adjustedLimit;
        let end = start + adjustedLimit;

        let array = activeArticles.articles.slice(start, end);
        setData(array);
    };

    const getLength = () => {
        return activeArticles.articles.length;
    };

    let totalPage = Math.ceil(getLength() / limit);

    useEffect(() => {
        getElement(page, limit);
    }, [page, limit]);

    const handleRowClick = (item) => {
        console.log(item.id)
        navigate(`/active-articles/${item.id}`, {
            state: {
                articulo: item,
                data: activeArticles.articles,
                page,
                limit
            }
        });
    };
 /*--------------------------------------------------------------------- */

    return (
        <div className='activeArticles_conatiner'>
        <div className='activeArticles_conatiner_h2'>
            <h2>Artículos</h2>

            <div className='activeArticles_conatiner_btn'>
                <button className='activeArticles_conatiner_btn_nuevo'>
                    <IoAdd size={24} /> Nuevo
                </button>
                <button className='activeArticles_conatiner_btn_opcion'>
                    <span style={{ fontSize: '20px' }}>⋮</span>
                </button>
            </div>
        </div>

        <div className='activeArticles_conatiner_tabla_wrapper'>
            <table className='activeArticles_conatiner_tabla'>
                <thead className='activeArticles_tabla_title'>
                    <tr>
                        <th>NOMBRE</th>
                        <th>SKU (CÓDIGO DE ARTÍCULO)</th>
                        <th>DESCRIPCIÓN DE COMPRA</th>
                        <th>TARIFA DE COMPRA</th>
                        <th>DESCRIPCIÓN</th>
                        <th>TARIFA</th>
                        <th>EXISTENCIAS A MANO</th>
                        <th>UNIDAD DE USO</th>
                        <th className="tabla_title_col-2"><CiSearch size={24} /></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className='activeArticles_tabla_fila'
                            onClick={() => handleRowClick(item)}>
                            <td>{item.nombre}</td>
                            <td>{item.sku}</td>
                            <td>{item.descripcion_compra}</td>
                            <td>{item.tarifa_compra}</td>
                            <td>{item.descripcion}</td>
                            <td>{item.tarifa}</td>
                            <td>{item.existencias_a_mano}</td>
                            <td>{item.unidad_de_uso}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

        <div className='activeArticles_pagination-container'>
            <div className='activeArticles_pagination-container-select'>
                <SelectLimit onLimitChange={setLimit} setPage={setPage} currentLimit={limit}/>
                <Pagination totalPage={totalPage} page={page} limit={limit}
                        siblings={1} onPageChange={handlePageChange}/>
            </div>
        </div>
    </div>
    );
};
