import React, { useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { tempContainer } from '../utils/ImgPDF';

export const CanvasPdf = ({ onHeightCalculated, factura }) => {

    const imgRef = useRef(null);

    const generatePDF = async () => {
        const pdfContainer = document.getElementById('imgPdf');
        const facturaContainer = tempContainer(factura); // Aquí generamos el contenedor dinámico

        pdfContainer.appendChild(facturaContainer);

        // Medir la altura del contenedor
        const tempContainerHeight = facturaContainer.offsetHeight;

        // Pasar la altura al componente padre
        if (onHeightCalculated) {
            onHeightCalculated(tempContainerHeight);
        }

        try {
            const canvas = await html2canvas(facturaContainer, { scale: 1 });
            const imgData = canvas.toDataURL('image/png');
            const doc = new jsPDF();
            doc.addImage(imgData, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
            const pdfUrl = doc.output('datauristring');

            if (imgRef.current) {
                imgRef.current.src = pdfUrl;
            }
        } catch (error) {
            console.error("Error generando el PDF", error);
        }
    };

    useEffect(() => {
        generatePDF();
    }, [factura]);

    return (
        <div id="imgPdf" className="facturacionDetallada_subConatiner_PDF">
            <img ref={imgRef} alt="Factura" />
        </div>
    );
};
