import React, { useState } from 'react';
import '../styles/newOrdenVenta.css';
import { CiCircleQuestion,CiSearch} from "react-icons/ci";
import { IoAddCircleSharp } from "react-icons/io5";
import { GoArrowRight } from "react-icons/go";
import { FaArrowsRotate } from "react-icons/fa6";
import { ArticleTable } from '../components/ArticleTable';


export const NewOrdenVenta = () => {
    const [rows, setRows] = useState([
        { id: 1, article: '', claveSAT: '', impuesto: '', cantidad: 1, tarifa: 0, descuento: 0, total: 0 },
        
    ]);

    const addRow = () => {
        const newRow = { id: rows.length + 1, article: '', claveSAT: '', impuesto: '', cantidad: 1, tarifa: 0, descuento: 0, total: 0 };
        setRows([...rows, newRow]);
    };

    const removeRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const handleInputChange = (id, field, value) => {
        const updatedRows = rows.map(row => {
            if (row.id === id) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setRows(updatedRows);
    };

    return (
        
        <form className="newOrdenVenta-form">
            <h2>Nueva Orden de Venta</h2>

            <div className="newOrdenVenta-form-group">
                <label style={{color:'#ea5253'}}>Nombre del cliente*</label>
                <div className='newOrdenVenta-form-group_btn'>
                    <select style={{width: '500px'}}>
                        <option value="">Seleccionar o añadir un cliente</option>
                        {/*Mas opciones */}
                    </select>
                    <button type="button" className="btn btn-search" >
                        <CiSearch size={24}/>
                    </button>
                </div>
            </div>

            <div className="newOrdenVenta-form-group">
                <label>Sucursal</label>
                <div >
                    <select className='newOrdenVenta-width'>
                        <option value="">Headquarters</option>
                    </select><br />
                    <span>Source Of Supply: Nuevo León</span>
                </div>
            </div>

            <hr />

            <div className="newOrdenVenta-form-group">
                <label style={{color:'#ea5253'}}>Orden de venta n°*</label>
                <div >
                    <select className='newOrdenVenta-width'>
                        <option value="">PX-2629</option>
                    </select>
                </div>
            </div>
            <div className="newOrdenVenta-form-group">
            <label>N.° de referencia</label>
                <div >
                    <select className='newOrdenVenta-width'>
                        <option value=""></option>
                    </select>
                </div>
            </div>
            <div className="newOrdenVenta-form-group">
                <label style={{color:'#ea5253'}}>Fecha de orden de venta* </label>
                <div >
                    <input  className='newOrdenVenta-width' type="date" value="2024-08-05"  />
                </div>
            </div>
            <div className="newOrdenVenta-form-group">
                <label>Fecha de envío esperada </label>
                <div >
                    <input  className='newOrdenVenta-width' type="date" value="" />
                </div>
            </div>
            <div className="newOrdenVenta-form-group">
                <label>Términos de pago</label>
                <div >
                    <select className='newOrdenVenta-width'>
                        <option value="">Pagadero a la recepción</option>
                    </select>
                </div>
            </div>

            <hr />

            <div className="newOrdenVenta-form-group">
                <label>Metodo de entrega</label>
                <div >
                    <select className='newOrdenVenta-width'>
                        <option value="" ></option>
                    </select>
                </div>
            </div>
            <div className="newOrdenVenta-form-group">
                <label>Vendedor</label>
                <div >
                    <select className='newOrdenVenta-width'>
                        <option value="">Edgar Ayala</option>
                    </select>
                </div>
            </div>
            <div className="newOrdenVenta-form-group">
                <label>FORMA DE PAGO</label>
                <div >
                    <select className='newOrdenVenta-width'>
                        <option value=""></option>
                    </select>
                    <span style={{color:'#000', marginLeft:'10px'}}>RFC</span>
                </div>
                
            </div>

            <hr />

            <div className="newOrdenVenta-form-group">
                <div>
                    <label>Almacen</label>
                    <select  value='' >
                        <option value="">Evolucion i3</option>
                        {/* Añade opciones aquí */}
                    </select>
                </div>
                <div >
                    <select style={{padding: '8px 50px'}} value='' >
                        <option value="">...</option>
                        {/* Añade opciones aquí */}
                    </select>
                </div>
            </div>

            <ArticleTable
                rows={rows}
                handleInputChange={handleInputChange}
                addRow={addRow}
                removeRow={removeRow}
            />


            <div className="newOrdenVenta-invoice-footer">
                    <div className="newOrdenVenta-invoice-notes">
                        <label>Notas del cliente</label>
                        <textarea ></textarea>
                        <span>Aparecerán en la factura</span>
                    </div>
                    <div className="newOrdenVenta-invoice-totals">
                        <div  className="newOrdenVenta-invoice-totals-subtotal">
                            <label style={{fontWeight:'700'}} htmlFor="">Subtotal</label>
                            <input className='newOrdenVenta-total-input' type="text"  value='' placeholder='0.00'/>
                        </div>
                        <div className="newOrdenVenta-invoice-totals-cargos">
                            <div className="newOrdenVenta-invoice-totals-cargos-label">
                                <label htmlFor="">Cargos de envío</label>
                                <p>Aplica impuesto sobre <br /> los gastos de envio</p>
                            </div>
                            <div className="newOrdenVenta-invoice-totals-cargos-input">
                                <input className="newOrdenVenta-invoice-totals-input" type="text"  value='' />
                                <CiCircleQuestion size={24}/>
                            </div>
                            <div className="newOrdenVenta-invoice-totals-cargos-in">
                                <input className='newOrdenVenta-total-input' type="text"  value='' placeholder='0.00'/>
                            </div>
                        </div>
                        <div className="newOrdenVenta-invoice-totals-cargos">
                            <div  className="newOrdenVenta-invoice-totals-cargos-label">
                                <label htmlFor="">Ajuste</label>
                            </div>
                            <div className="newOrdenVenta-invoice-totals-cargos-input">
                                <input className="newOrdenVenta-invoice-totals-input" type="text"  value='' />
                                <CiCircleQuestion size={24} />
                            </div>
                            <div className="newOrdenVenta-invoice-totals-cargos-in">
                                <input className='newOrdenVenta-total-input' type="text"  value='' placeholder='0.00'/>
                            </div>
                        </div>

                        <div className="newOrdenVenta-invoice-totals-subtotal">
                            <label style={{fontWeight:'700'}}  htmlFor="">Total (MXN$)</label>
                            <input className='newOrdenVenta-total-input' type="text"  value='' placeholder='0.00'/>
                            
                        </div>
                    
                    </div>

                </div>

                <hr />

                <div className='newOrdenVenta-form-group'>
                    <div>
                        <p>Términos y condiciones</p>
                        <textarea
                            style={{ width: '600px', height: '100px' }}
                            type="text"
                            value=''
                            placeholder='PRODUCTOS Y SERVICIOS VENDIDOS ESTÁN EXPRESAMENTE SUJETOS A LOS TÉRMINOS Y CONDICIONES ESTABLECIDOS EN EL PRESENTE Y EN EL SITIO WEB DE EVOLUCIÓN I3, WWW.EVOlUCIONI3.COM, SIN PERJUICIO DE CUALQUIER TÉRMINO CONTRADICTORIO DE LA ORDEN'
                        />
                    </div>
                    <div className='newOrdenVenta-form-group_archivo'>
                        <p>Adjuntar archivo(s) a Factura</p>
                        <input type="file" multiple /> <br />
                        <span >Puede cargar un máximo de 10 archivos, cada uno de 10 MB</span>
                    </div>
                </div>

                <div className='newOrdenVenta-form-group-formadepago'>
                    <p style={{marginTop:'50px'}}>Campos adicionales:Para agregar campos personlizados para sus facturas, acceda a Configuración, <GoArrowRight /> Ventas <GoArrowRight /> facturas.</p>
                </div>
                <hr />

            <div className='newOrdenVenta_footer'>
                <div className='newOrdenVenta_footer_btn'>
                    <button className='btn footer_btn'>Guardar como borrador</button>
                    <button className='btn btn-primary'>Guardar y enviar</button>
                    <button className='btn footer_btn'>Cancelar</button>
                </div>

                <div className='newOrdenVenta_footer_total'>
                    <p className='newOrdenVenta_footer_total_p'> <FaArrowsRotate />Hacer recurrente</p>
                    <div className='newOrdenVenta_footer_cantidadTotal'>
                        <p>Cantidad total: MXN$ 0.00</p>
                        <span>Cantidad total: 0</span>
                    </div>
                </div>

            </div>
        </form>

    )
}


